import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var LanguageStatus;
(function (LanguageStatus) {
    LanguageStatus[LanguageStatus["Deactivated"] = -1] = "Deactivated";
    LanguageStatus[LanguageStatus["Active"] = 1] = "Active";
})(LanguageStatus || (LanguageStatus = {}));
export const LanguageStatusMap = new Map([
    [LanguageStatus.Deactivated, 'Global.Options.LanguageStatus.Deactivated'],
    [LanguageStatus.Active, 'Global.Status.Active'],
]);
let LanguageStatusService = class LanguageStatusService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = LanguageStatusMap;
    }
    list() {
        return this._data;
    }
    get(key) {
        const k = this._data.get(key);
        return k ? this._translate.instant(k) : k;
    }
    getStatusClass(status) {
        switch (status) {
            case LanguageStatus.Active:
                return 'text-success';
            case LanguageStatus.Deactivated:
                return 'text-danger';
            default:
                return '';
        }
    }
};
LanguageStatusService.ctorParameters = () => [
    { type: TranslateService }
];
LanguageStatusService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], LanguageStatusService);
export { LanguageStatusService };
