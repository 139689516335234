import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserManagementApiService } from './user-management-api.service';
import { EmailStatusService, IntegrationStatusService, UserLeadStatusLabelsService, UserStatus, UserStatusService, } from '@ho/shared/data-access/dictionary';
import { map, pluck } from 'rxjs/operators';
import { map as _map } from 'lodash-es';
import { SessionUserTypeService, UserWorkspaceRole } from '@ho/shared/data-access/session';
let UserManagementService = class UserManagementService {
    constructor(_userManagementApiService, _sessionUserTypeService, _userLeadStatusLabelsService, _userStatusService, _emailStatusService, _integrationStatusService, _translate) {
        this._userManagementApiService = _userManagementApiService;
        this._sessionUserTypeService = _sessionUserTypeService;
        this._userLeadStatusLabelsService = _userLeadStatusLabelsService;
        this._userStatusService = _userStatusService;
        this._emailStatusService = _emailStatusService;
        this._integrationStatusService = _integrationStatusService;
        this._translate = _translate;
    }
    affiliates(body) {
        return this._userManagementApiService.affiliates(body).pipe(map((response) => {
            response.body['data'] = _map(response.body['data'], (affiliateRequest) => {
                return Object.assign(Object.assign({}, affiliateRequest), { isBanned: affiliateRequest.status_id === UserStatus.Banned, postbacksLink: `/d/user-operations/affiliates/${affiliateRequest.id}/postbacks`, isLink: this._sessionUserTypeService.isAdminRole() ||
                        affiliateRequest.invited_by_workspace_role_id === UserWorkspaceRole.AM });
            });
            return response;
        }));
    }
    managers(body) {
        return this._userManagementApiService.managers(body).pipe(map((response) => {
            response.body['data'] = _map(response.body['data'], (managerRequest) => {
                return Object.assign(Object.assign({}, managerRequest), { isBanned: managerRequest.status_id === UserStatus.Banned });
            });
            return response;
        }));
    }
    advertisers(body) {
        return this._userManagementApiService.advertisers(body).pipe(map((response) => {
            response.body['data'] = _map(response.body['data'], (advertiserRequest) => {
                return Object.assign(Object.assign({}, advertiserRequest), { isBanned: advertiserRequest.status_id === UserStatus.Banned });
            });
            return response;
        }));
    }
    userAccounts(body) {
        return this._userManagementApiService.userAccounts(body);
    }
    affiliateRequests(body) {
        return this._userManagementApiService.affiliateRequests(body).pipe(map((response) => {
            response.body['data'] = _map(response.body['data'], (userRequest) => {
                return Object.assign(Object.assign({}, userRequest), { statusClass: this._userLeadStatusLabelsService.getStyleClass(userRequest.lead_status), isLink: this._sessionUserTypeService.isAdminRole() ||
                        userRequest.invited_by_workspace_role_id === UserWorkspaceRole.AM });
            });
            return response;
        }));
    }
    affiliateRegistrationStats(body) {
        return this._userManagementApiService.affiliateRegistrationStats(body).pipe(map((response) => {
            response.body['data'] = _map(response.body['data'], (stats) => {
                return Object.assign(Object.assign({}, stats), { isEmailConfirmedStatusClass: this._emailStatusService.getStyleClass(stats.is_email_confirmed), integrationStatusClass: this._integrationStatusService.getStyleClass(stats.integration_status), integrationStatusName: this._integrationStatusService.get(stats.integration_status), leadStatusClass: this._userLeadStatusLabelsService.getStyleClass(stats.lead_status), leadStatusName: this._userLeadStatusLabelsService.get(stats.lead_status), systemStatusClass: this._userStatusService.getStyleClass(stats.status), systemStatusName: this._userStatusService.get(stats.status), emailStatus: this._emailStatusService.get(stats.is_email_confirmed), isLinkInvitedBy: this._sessionUserTypeService.isAdminRole() ||
                        stats.invited_by_workspace_role_id === UserWorkspaceRole.AM, isLinkAffiliateManager: this._sessionUserTypeService.isAdminRole() ||
                        stats.affiliate_manager_workspace_role_id === UserWorkspaceRole.AM });
            });
            return response;
        }));
    }
    exportRegistrationStatsCsv(params) {
        return this._userManagementApiService.exportRegistrationStatsCsv(params);
    }
    emailVerification(body) {
        return this._userManagementApiService.emailVerification(body);
    }
    getAffiliateRequest(id) {
        return this._userManagementApiService.getAffiliateRequest(id).pipe(pluck('data'));
    }
    batchApproveRequests(body) {
        return this._userManagementApiService.batchApproveRequests(body).pipe(pluck('data'));
    }
    batchDeclineRequests(body) {
        return this._userManagementApiService.batchDeclineRequests(body).pipe(pluck('data'));
    }
    batchSetLeadStatus(body, type) {
        return this._userManagementApiService.batchSetStatus(body, type).pipe(pluck('data'));
    }
    commentAccount(body, id) {
        return this._userManagementApiService.commentAccount(body, id).pipe(pluck('data'));
    }
    commentRequest(body, id) {
        return this._userManagementApiService.commentRequest(body, id).pipe(pluck('data'));
    }
    getAccount(id) {
        return this._userManagementApiService.getAccount(id).pipe(pluck('data'));
    }
    getAccountRole(id) {
        return this._userManagementApiService.getAccountRole(id).pipe(pluck('data'));
    }
    banAccount(id, body) {
        return this._userManagementApiService.banAccount(id, body);
    }
    unbanAccount(id, body) {
        return this._userManagementApiService.unbanAccount(id, body);
    }
    getProfile(id) {
        return this._userManagementApiService.getProfile(id).pipe(pluck('data'));
    }
    patchAffiliateProfile(id, section, params) {
        return this._userManagementApiService.patchAffiliateProfile(id, section, params).pipe(pluck('data'));
    }
    updateAccount(id, body) {
        return this._userManagementApiService.updateAccount(id, body).pipe(pluck('data'));
    }
    changeAccountPassword(id, body) {
        return this._userManagementApiService.changeAccountPassword(id, body);
    }
    userChangeLog(body) {
        return this._userManagementApiService.userChangeLog(body);
    }
    exportCsv(params) {
        return this._userManagementApiService.exportCsv(params);
    }
    registrationLogExportPdf(params) {
        return this._userManagementApiService.registrationLogExportPdf(params);
    }
    setTrafficChannel(id, body) {
        return this._userManagementApiService.setTrafficChannel(id, body).pipe(pluck('data'));
    }
    createAffiliate(body) {
        return this._userManagementApiService.createAffiliate(body);
    }
    createManager(body) {
        return this._userManagementApiService.createManager(body);
    }
    createAD(body) {
        return this._userManagementApiService.createAD(body);
    }
    createAdmin(body) {
        return this._userManagementApiService.createAdmin(body);
    }
    batchChangeManager(body) {
        return this._userManagementApiService.batchChangeManager(body).pipe(pluck('data'));
    }
    changeSecretKey(id, body) {
        return this._userManagementApiService.changeSecretKey(id, body).pipe(pluck('data'));
    }
};
UserManagementService.ctorParameters = () => [
    { type: UserManagementApiService },
    { type: SessionUserTypeService },
    { type: UserLeadStatusLabelsService },
    { type: UserStatusService },
    { type: EmailStatusService },
    { type: IntegrationStatusService },
    { type: TranslateService }
];
UserManagementService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [UserManagementApiService,
        SessionUserTypeService,
        UserLeadStatusLabelsService,
        UserStatusService,
        EmailStatusService,
        IntegrationStatusService,
        TranslateService])
], UserManagementService);
export { UserManagementService };
