import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var UserIntegrationStatus;
(function (UserIntegrationStatus) {
    UserIntegrationStatus["New"] = "new";
    UserIntegrationStatus["Negotiation"] = "negotiation";
    UserIntegrationStatus["Integrating"] = "integrating";
    UserIntegrationStatus["WaitingForLaunch"] = "waiting_for_launch";
    UserIntegrationStatus["Live"] = "live";
    UserIntegrationStatus["Paused"] = "paused";
    UserIntegrationStatus["Banned"] = "banned";
    UserIntegrationStatus["Approved"] = "approved";
})(UserIntegrationStatus || (UserIntegrationStatus = {}));
export const UserIntegrationStatusLabels = new Map([
    [UserIntegrationStatus.New, 'Global.Status.New'],
    [UserIntegrationStatus.Negotiation, 'Global.UserIntegrationStatus.Negotiation'],
    [UserIntegrationStatus.Integrating, 'Global.UserIntegrationStatus.Integrating'],
    [UserIntegrationStatus.WaitingForLaunch, 'Global.UserIntegrationStatus.WaitingForLaunch'],
    [UserIntegrationStatus.Live, 'Global.UserIntegrationStatus.Live'],
    [UserIntegrationStatus.Paused, 'Global.UserIntegrationStatus.Paused'],
    [UserIntegrationStatus.Banned, 'Global.UserIntegrationStatus.Banned'],
    [UserIntegrationStatus.Approved, 'Global.Status.Approved'],
]);
let UserIntegrationStatusService = class UserIntegrationStatusService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = UserIntegrationStatusLabels;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
    getStatusClass(status) {
        switch (status) {
            case UserIntegrationStatus.Live:
                return 'text-success';
            case UserIntegrationStatus.Banned:
                return 'text-danger';
            case UserIntegrationStatus.Paused:
            case UserIntegrationStatus.WaitingForLaunch:
            case UserIntegrationStatus.Integrating:
            case UserIntegrationStatus.Negotiation:
            case UserIntegrationStatus.New:
                return 'text-warning';
            default:
                return '';
        }
    }
};
UserIntegrationStatusService.ctorParameters = () => [
    { type: TranslateService }
];
UserIntegrationStatusService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], UserIntegrationStatusService);
export { UserIntegrationStatusService };
