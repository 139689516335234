import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { merge } from 'lodash-es';
import { UiSettingsGridCacheStore } from '../+state/ui-settings-grid-cache.store';
import { UiSettingsGridCacheQuery } from '../+state/ui-settings-grid-cache.query';
import { UiSettingsApiService } from './ui-settings-api.service';
import { finalize } from 'rxjs/internal/operators';
let UiSettingsQueueService = class UiSettingsQueueService {
    constructor(_uiSettingsApiService, _uiSettingsGridCacheStore, _uiSettingsGridCacheQuery) {
        this._uiSettingsApiService = _uiSettingsApiService;
        this._uiSettingsGridCacheStore = _uiSettingsGridCacheStore;
        this._uiSettingsGridCacheQuery = _uiSettingsGridCacheQuery;
        this._start();
    }
    invoke(data) {
        this._uiSettingsGridCacheStore.addCache({ [data.grid_id]: data.grid_settings });
        this._startRequest();
    }
    _startRequest() {
        if (this._inProgress) {
            return;
        }
        if (this._timer) {
            clearTimeout(this._timer);
        }
        this._timer = setTimeout(() => {
            this._start();
        }, 15000);
    }
    _start() {
        const cache = this._uiSettingsGridCacheQuery.getCache();
        const payload = this._uiSettingsGridCacheQuery.getPayload();
        const dataPayload = merge(cache, payload);
        if (!dataPayload || !Object.keys(dataPayload).length) {
            return;
        }
        this._inProgress = true;
        this._uiSettingsGridCacheStore.addPayload(cache);
        this._uiSettingsGridCacheStore.removeCache();
        const body = Object.keys(dataPayload).map((key) => {
            return {
                grid_id: key,
                ui_settings: dataPayload[key],
            };
        });
        this._uiSettingsApiService
            .saveGridSettings(body)
            .pipe(finalize(() => {
            this._inProgress = false;
        }))
            .subscribe(() => {
            this._uiSettingsGridCacheStore.removePayload();
            this._startRequest();
        }, () => {
            this._uiSettingsGridCacheStore.addCache(dataPayload);
            this._uiSettingsGridCacheStore.removePayload();
            this._startRequest();
        });
    }
};
UiSettingsQueueService.ctorParameters = () => [
    { type: UiSettingsApiService },
    { type: UiSettingsGridCacheStore },
    { type: UiSettingsGridCacheQuery }
];
UiSettingsQueueService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [UiSettingsApiService,
        UiSettingsGridCacheStore,
        UiSettingsGridCacheQuery])
], UiSettingsQueueService);
export { UiSettingsQueueService };
