import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TimezonesService } from '@ho/shared/data-access/core-data-access';
import { CountryCapacityRulesApiService, } from '@ho/shared/data-access/core/lib/+state/country-capacity-rules-api.service';
import { map as _map } from 'lodash-es';
import { DateTime } from 'luxon';
import { map } from 'rxjs/operators';
let CountryCapacityRulesService = class CountryCapacityRulesService {
    constructor(_countryCapacityRulesApiService, _timezoneService) {
        this._countryCapacityRulesApiService = _countryCapacityRulesApiService;
        this._timezoneService = _timezoneService;
    }
    list(body) {
        return this._countryCapacityRulesApiService.list(body);
    }
    listWithParsedWorkingHours(body) {
        const localTime = DateTime.local();
        return this._countryCapacityRulesApiService.list(body).pipe(map((response) => {
            response.body['data'] = _map(response.body['data'], (countryCapacity) => {
                return Object.assign(Object.assign({}, countryCapacity), { working_hours_start: !!countryCapacity.working_hours_start && !!countryCapacity.working_hours_finish
                        ? localTime
                            .setZone(countryCapacity.working_hours_tz)
                            .set({ hour: countryCapacity.working_hours_start })
                            .setZone(this._timezoneService.getTimeZoneName())
                            .toFormat('HH')
                        : 0, working_hours_finish: !!countryCapacity.working_hours_start && !!countryCapacity.working_hours_finish
                        ? localTime
                            .setZone(countryCapacity.working_hours_tz)
                            .set({ hour: countryCapacity.working_hours_finish })
                            .setZone(this._timezoneService.getTimeZoneName())
                            .toFormat('HH')
                        : 24 });
            });
            return response;
        }));
    }
    create(body) {
        return this._countryCapacityRulesApiService.create(body);
    }
    getRule(id) {
        return this._countryCapacityRulesApiService.getRule(id);
    }
    editRule(id, body) {
        return this._countryCapacityRulesApiService.editRule(id, body);
    }
    deleteRule(id) {
        return this._countryCapacityRulesApiService.deleteRule(id);
    }
    setTop(id, isTop) {
        return this._countryCapacityRulesApiService.setTop(id, isTop);
    }
};
CountryCapacityRulesService.ctorParameters = () => [
    { type: CountryCapacityRulesApiService },
    { type: TimezonesService }
];
CountryCapacityRulesService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [CountryCapacityRulesApiService,
        TimezonesService])
], CountryCapacityRulesService);
export { CountryCapacityRulesService };
