import { __decorate, __metadata } from "tslib";
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SentryErrorHandler } from '@ho/shared/data-access/core/lib/services/sentry.service';
import { WlCurrency, WlCurrencySymbolMap } from '@ho/tools/environments';
import { pluck } from 'rxjs/operators';
import { AppEnvironment } from '@ho/shared/data-access/core/app-environment.model';
import { DOCUMENT } from '@angular/common';
import { mergeWith as _mergeWith, isEmpty } from 'lodash-es';
import { ColorSchemeFactory } from '../../../../../../theming/src/lib/services/color-scheme.factory';
let CustomAppConfigService = class CustomAppConfigService {
    constructor(_httpClient, _sentry, environment, document) {
        this._httpClient = _httpClient;
        this._sentry = _sentry;
        this.environment = environment;
        this.document = document;
        this._endpointUrl = '/wl/config';
        this.wlCurrencySymbol = WlCurrency.USD;
    }
    init() {
        var _a, _b, _c;
        const schemeFactory = new ColorSchemeFactory(document);
        this.config = {
            wlId: null,
            wlAuthLogo: this.environment.wlAuthLogo,
            wlMainLargeLogo: this.environment.wlMainLargeLogo,
            wlMainSmallLogo: this.environment.wlMainSmallLogo,
            wlFavicon: this.environment.wlFavicon,
            wlPreloader: this.environment.wlPreloader,
            wlCompanyName: this.environment.wlCompanyName,
            wlHomePageLink: this.environment.wlHomePageLink,
            wlTNCLink: this.environment.wlTNCLink,
            wlSupportEmail: this.environment.wlSupportEmail,
            wlSupportSkype: this.environment.wlSupportSkype,
            wlRegistrationSupportSkype: this.environment.wlRegistrationSupportSkype,
            wlRegistrationSupportTelegram: this.environment.wlRegistrationSupportTelegram,
            wlCurrency: this.environment.wlCurrency,
            wlColorScheme: this.environment.wlColorScheme,
            wlSighInBackgroundImage: this.environment.wlSighInBackgroundImage,
            wlPrivacyPolicyLink: this.environment.wlPrivacyPolicyLink,
        };
        /* th:start */
        if (!this.config.wlSighInBackgroundImage || ((_b = (_a = this.config) === null || _a === void 0 ? void 0 : _a.wlSighInBackgroundImage) === null || _b === void 0 ? void 0 : _b.includes('default/sign-in__bg.jpg'))) {
            this.config.wlSighInBackgroundImage = '';
        }
        /* th:end */
        schemeFactory.patchPropertiesFromWlConfig((_c = this.config.wlColorScheme) === null || _c === void 0 ? void 0 : _c.color_scheme);
        return this._httpClient
            .get(this._endpointUrl)
            .pipe(pluck('data'))
            .toPromise()
            .then((serverConfigs) => {
            var _a;
            this.wlConfigRaw = serverConfigs;
            serverConfigs.wlSighInBackgroundImage = ((_a = serverConfigs === null || serverConfigs === void 0 ? void 0 : serverConfigs.wlSighInBackgroundImage) === null || _a === void 0 ? void 0 : _a.includes('default/sign-in__bg.jpg')) ? ''
                : serverConfigs.wlSighInBackgroundImage;
            this.config = _mergeWith({}, this.config, serverConfigs, (item, itemServer) => isEmpty(itemServer) ? item : itemServer);
            this._initStyles(this.config);
            this._sentry.init(this.config);
            this.wlCurrency = this._config.wlCurrency;
            this.wlCurrencySymbol = WlCurrencySymbolMap.get(this._config.wlCurrency);
        });
    }
    set config(value) {
        const faviconPath = value.wlId
            ? value.wlFavicon && value.wlFavicon.length
                ? value.wlFavicon
                : './assets/images/favicon.ico'
            : '';
        this._config = value;
        this.document.head.getElementsByClassName('favicon')[0].setAttribute('href', faviconPath);
    }
    get config() {
        return this._config;
    }
    get wlId() {
        return this._config.wlId;
    }
    get wlAuthLogo() {
        return this._config.wlAuthLogo;
    }
    get wlMainLargeLogo() {
        return this._config.wlMainLargeLogo;
    }
    get wlMainSmallLogo() {
        return this._config.wlMainSmallLogo;
    }
    get wlFavicon() {
        return this._config.wlFavicon;
    }
    get wlPreloader() {
        return this._config.wlPreloader;
    }
    get wlCompanyName() {
        return this._config.wlCompanyName;
    }
    get wlHomePageLink() {
        return this._config.wlHomePageLink;
    }
    get wlTNCLink() {
        return this._config.wlTNCLink;
    }
    get wlPrivacyPolicyLink() {
        return this._config.wlPrivacyPolicyLink;
    }
    get wlSupportEmail() {
        return this._config.wlSupportEmail;
    }
    get wlSupportSkype() {
        return this._config.wlSupportSkype;
    }
    get wlRegistrationSupportSkype() {
        return this._config.wlRegistrationSupportSkype;
    }
    get wlRegistrationSupportTelegram() {
        return this._config.wlRegistrationSupportTelegram;
    }
    get wlFeatures() {
        return this._config.wlFeatures;
    }
    get wlColorScheme() {
        return this._config.wlColorScheme;
    }
    get wlSighInBackgroundImage() {
        return this._config.wlSighInBackgroundImage;
    }
    _initStyles(configs) {
        if (configs.wlPreloader) {
            const style = document.createElement('style');
            style.innerHTML = `.loading:after{background-image: url(${configs.wlPreloader}) !important;}`;
            this.document.head.appendChild(style);
        }
    }
};
CustomAppConfigService.ctorParameters = () => [
    { type: HttpClient },
    { type: SentryErrorHandler },
    { type: undefined, decorators: [{ type: Inject, args: [AppEnvironment,] }] },
    { type: Document, decorators: [{ type: Inject, args: [DOCUMENT,] }] }
];
CustomAppConfigService = __decorate([
    Injectable({ providedIn: 'root' }),
    __metadata("design:paramtypes", [HttpClient,
        SentryErrorHandler, Object, Document])
], CustomAppConfigService);
export { CustomAppConfigService };
