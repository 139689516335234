import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { UiSettingsApiService } from '../services/ui-settings-api.service';
import { map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { UiSettingsStore } from '../+state/ui-settings.store';
import { UiSettingsQuery } from '../+state/ui-settings.query';
let UiSettingsService = class UiSettingsService {
    constructor(_uiSettingsApiService, _uiSettingsStore, _uiSettingsQuery) {
        this._uiSettingsApiService = _uiSettingsApiService;
        this._uiSettingsStore = _uiSettingsStore;
        this._uiSettingsQuery = _uiSettingsQuery;
    }
    getUiSettings() {
        const observable = localStorage.getItem('user_view_type')
            ? of({
                grid_settings: null,
                dashboard_settings: null,
                modal_settings: null,
                theme_settings: {
                    selected_color_scheme: localStorage.getItem('selected_color_scheme') || 'Light',
                },
                tutorial_settings: { skip_tutorial: !!localStorage.getItem('skip_tutorial') },
            })
            : this._uiSettingsApiService.getUiSettings();
        return observable.pipe(tap((res) => this._uiSettingsStore.updateUiSettings(res)), map((r) => r));
    }
};
UiSettingsService.ctorParameters = () => [
    { type: UiSettingsApiService },
    { type: UiSettingsStore },
    { type: UiSettingsQuery }
];
UiSettingsService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [UiSettingsApiService,
        UiSettingsStore,
        UiSettingsQuery])
], UiSettingsService);
export { UiSettingsService };
