import { __decorate, __metadata } from "tslib";
import __NG_CLI_RESOURCE__0 from "!raw-loader!./permissions-block-list.container.html";
import __NG_CLI_RESOURCE__1 from "./permissions-block-list.container.css";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { SettingsService } from '../../../../../../data-access/core/src/lib/+state/settings.service';
import { UserWorkspaceRole } from '../../../../../../data-access/session/src/lib/state/session-user-type-service.service';
import { PermissionsService } from '@ho/libs/shared/services/permissions.service';
import { PermissionSettingsService } from '../../services/permission-settings.service';
import { permissionsList } from '../../permission-settings.model';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { finalize, pluck } from 'rxjs/operators';
import { SnackbarService } from '@ho/shared/ui/snack-bar';
import { TranslateService } from '@ngx-translate/core';
let PermissionsBlockListContainer = class PermissionsBlockListContainer {
    constructor(_service, _fb, _snack, _cdr, _permissionsService, _translate, _settings) {
        this._service = _service;
        this._fb = _fb;
        this._snack = _snack;
        this._cdr = _cdr;
        this._permissionsService = _permissionsService;
        this._translate = _translate;
        this._settings = _settings;
        this.form = this._fb.array([]);
        this._rolePermission = {
            [UserWorkspaceRole.Admin]: 'UI_PermissionSettings_ManageAdmin',
            [UserWorkspaceRole.ATL]: 'UI_PermissionSettings_ManageTL',
            [UserWorkspaceRole.AM]: 'UI_PermissionSettings_ManageManager',
            [UserWorkspaceRole.Aff]: 'UI_PermissionSettings_ManageAffiliate',
            [UserWorkspaceRole.AD]: 'UI_PermissionSettings_ManageAdvertiser',
        };
    }
    set role(value) {
        if (!!value) {
            this._role = value;
            const [hasPermission] = this._permissionsService.hasPermissions([this._rolePermission[value]]);
            this.hasPermissionToEdit = hasPermission;
        }
    }
    get role() {
        return this._role;
    }
    set userId(value) {
        if (!!value) {
            this._userId = value;
            this.setPermissionsForm();
        }
    }
    get userId() {
        return this._userId;
    }
    groupByIndex(index) {
        return this.form.get(index.toString());
    }
    onSave() {
        const activePermissions = [];
        this.loading = true;
        this.form.getRawValue().forEach((permissions) => {
            Object.keys(permissions).forEach((permissionName) => {
                if (permissions[permissionName]) {
                    activePermissions.push(permissionName);
                }
            });
        });
        if (this.role === UserWorkspaceRole.Aff) {
            activePermissions.push('UI_OfferCampaigns_View');
        }
        activePermissions.push('UI_FraudDetection_View');
        activePermissions.push('UI_FraudDetection_Explain');
        this._service
            .setUserPermissions({
            user_id: this.userId,
            permissions: activePermissions,
        })
            .pipe(finalize(() => {
            this.loading = false;
            this._cdr.detectChanges();
        }), untilDestroyed(this))
            .subscribe((_) => {
            this._snack.success(this._translate.instant('Permission.Title.PermissionSettings'), this._translate.instant('Global.Notification.PermissionsUpdated.Success'));
            this.cachedUserPermissions = [...activePermissions];
            this.form.markAsPristine();
        }, (err) => {
            this._snack.error(this._translate.instant('Permission.Title.PermissionSettings'), this._translate.instant('Global.Notification.ReplaceDomain.Error'));
        });
    }
    onCancel() {
        this.permissionsList.forEach((rolePermission, key) => {
            this.permissionsList[key].permissions.forEach((permission) => {
                const group = this.groupByIndex(key);
                group.get(permission['key']).setValue(!!~this.cachedUserPermissions.indexOf(permission['key']));
                group.markAsPristine();
            });
        });
    }
    ngOnDestroy() { }
    ngOnInit() {
        this.permissionsList = permissionsList
            .filter((item) => {
            return (item.hasOwnProperty('feature') &&
                this._settings.settings.workspace_features.includes(item.feature) &&
                item.roles.includes(this.role));
        })
            .map((item) => {
            item.permissions = item.permissions.filter((permission) => !permission.hasOwnProperty('excludeRoles') ||
                (permission.hasOwnProperty('excludeRoles') && !permission.excludeRoles.includes(this.role)));
            return item;
        });
    }
    setPermissionsForm() {
        this.loading = true;
        this._service
            .activeUserPermissions(this.userId)
            .pipe(finalize(() => {
            this.loading = false;
            this._cdr.detectChanges();
        }), untilDestroyed(this), pluck('data'))
            .subscribe((response) => {
            this._initForm(response);
        });
    }
    _initGroupedPermissionControlsSubscriptions(group, permissionsWIthChildren) {
        permissionsWIthChildren.forEach((permission) => {
            group
                .get(permission['key'])
                .valueChanges.pipe(untilDestroyed(this))
                .subscribe((value) => {
                permission.childrenPermissions.forEach((childPermissionKey) => {
                    if (group.get(childPermissionKey)) {
                        if (!value) {
                            group.get(childPermissionKey).setValue(value);
                        }
                        group.get(childPermissionKey)[value && this.hasPermissionToEdit ? 'enable' : 'disable']();
                    }
                });
            });
            // FIXME  when dashboard for advertiser will be done
            if (this.role === UserWorkspaceRole.AD && permission['key'] === 'UI_OfferCampaigns_View') {
                group.get(permission['key']).disable();
                group.get(permission['key']).setValue(true);
            }
            group.get(permission['key']).updateValueAndValidity();
        });
    }
    _initForm(userPermissions) {
        this.cachedUserPermissions = [...userPermissions];
        this.form = this._fb.array([]);
        this.permissionsList.forEach((rolePermission, key) => {
            this.form.push(this._fb.group({}));
            const group = this.groupByIndex(key);
            const permissionsWIthChildren = [];
            rolePermission.permissions.forEach((permission) => {
                group.addControl(permission['key'], new FormControl(userPermissions.includes(permission['key'])));
                if (permission.childrenPermissions) {
                    permissionsWIthChildren.push(permission);
                }
                if (!this.hasPermissionToEdit) {
                    group.get(permission['key']).disable();
                }
            });
            this._initGroupedPermissionControlsSubscriptions(group, permissionsWIthChildren);
        });
        this._cdr.detectChanges();
    }
};
PermissionsBlockListContainer.ctorParameters = () => [
    { type: PermissionSettingsService },
    { type: FormBuilder },
    { type: SnackbarService },
    { type: ChangeDetectorRef },
    { type: PermissionsService },
    { type: TranslateService },
    { type: SettingsService }
];
PermissionsBlockListContainer.propDecorators = {
    role: [{ type: Input }],
    userId: [{ type: Input }]
};
PermissionsBlockListContainer = __decorate([
    Component({
        selector: 'ho-permissions-block-list',
        template: __NG_CLI_RESOURCE__0,
        changeDetection: ChangeDetectionStrategy.OnPush,
        styles: [__NG_CLI_RESOURCE__1]
    }),
    __metadata("design:paramtypes", [PermissionSettingsService,
        FormBuilder,
        SnackbarService,
        ChangeDetectorRef,
        PermissionsService,
        TranslateService,
        SettingsService])
], PermissionsBlockListContainer);
export { PermissionsBlockListContainer };
