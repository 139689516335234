import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { map as _map } from 'lodash-es';
import { CountryFlagService } from '@ho/shared/widgets/country-flag-widget';
let CountryCapacityRulesApiService = class CountryCapacityRulesApiService {
    constructor(_httpClient, _countryFlagService) {
        this._httpClient = _httpClient;
        this._countryFlagService = _countryFlagService;
        this.endpointUrl = '/feature/country-capacity-rules';
    }
    list(body) {
        return this._httpClient
            .post(`/capacity/settings`, body, { observe: 'response' })
            .pipe(map((response) => {
            response.body['data'] = _map(response.body['data'], (countryCapacityItem) => {
                return Object.assign(Object.assign({}, countryCapacityItem), { country_alpha_code: this._countryFlagService.getCountryCode(countryCapacityItem.country_id) });
            });
            return response;
        }));
    }
    create(body) {
        return this._httpClient.post(`/capacity/create`, body);
    }
    getRule(id) {
        return this._httpClient.get(`${this.endpointUrl}/${id}`);
    }
    editRule(id, body) {
        return this._httpClient.put(`/capacity/${id}`, body);
    }
    deleteRule(id) {
        return this._httpClient.delete(`/capacity/${id}`);
    }
    setTop(id, isTop) {
        return this._httpClient.post(`/capacity/${id}/toggle-top`, { is_top: isTop });
    }
};
CountryCapacityRulesApiService.ctorParameters = () => [
    { type: HttpClient },
    { type: CountryFlagService }
];
CountryCapacityRulesApiService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [HttpClient, CountryFlagService])
], CountryCapacityRulesApiService);
export { CountryCapacityRulesApiService };
