import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { StoreNames } from '@ho/shared/constants/store-config.enum';
import { DEFAULT_DASHBOARD_SETTINGS } from '../constants/dashboard-default-config';
import { cloneDeep, merge } from 'lodash-es';
let UiSettingsStore = class UiSettingsStore extends Store {
    constructor() {
        super({});
    }
    updateUiSettings(settings) {
        const model = {
            grid_settings: settings.grid_settings || {},
            dashboard_settings: cloneDeep(merge(DEFAULT_DASHBOARD_SETTINGS, this._mergeDashboardSettingsWithDefaultConfig(DEFAULT_DASHBOARD_SETTINGS, settings.dashboard_settings || {})) || {}),
            modal_settings: settings.modal_settings || {},
            theme_settings: settings.theme_settings || { selected_color_scheme: 'Light' },
            tutorial_settings: settings.tutorial_settings || { skip_tutorial: false },
        };
        this.update((state) => (Object.assign(Object.assign({}, state), model)));
    }
    updateGridSettings(gridSettings = {}) {
        this.update((state) => {
            return Object.assign(Object.assign({}, state), { grid_settings: merge(Object.assign(Object.assign({}, state.grid_settings), gridSettings)) });
        });
    }
    updateDashboardSettings(dashboardSettings = {}) {
        this.update((state) => {
            return Object.assign(Object.assign({}, state), { dashboard_settings: cloneDeep(merge(DEFAULT_DASHBOARD_SETTINGS, state.dashboard_settings, dashboardSettings)) });
        });
    }
    updateModalSettings(modalSettings = {}) {
        this.update((state) => {
            return Object.assign(Object.assign({}, state), { modal_settings: Object.assign(Object.assign({}, cloneDeep(state.modal_settings)), modalSettings) });
        });
    }
    updateThemeSettings(themeSettings) {
        this.update((state) => {
            return Object.assign(Object.assign({}, state), { theme_settings: Object.assign(Object.assign({}, state.theme_settings), themeSettings) });
        });
    }
    updateTutorialSettings(tutorialSettings) {
        this.update((state) => {
            return Object.assign(Object.assign({}, state), { tutorial_settings: Object.assign(Object.assign({}, state.tutorial_settings), tutorialSettings) });
        });
        localStorage.setItem('skip_tutorial', tutorialSettings.skip_tutorial.toString());
    }
    _mergeDashboardSettingsWithDefaultConfig(defaultDashboardSettings, dashboardSettingsFromApi) {
        return Object.keys(defaultDashboardSettings).reduce((acc, key) => {
            acc[key] =
                defaultDashboardSettings[key] &&
                    dashboardSettingsFromApi[key] &&
                    defaultDashboardSettings[key].version === dashboardSettingsFromApi[key].version
                    ? dashboardSettingsFromApi[key]
                    : defaultDashboardSettings[key];
            return acc;
        }, {});
    }
};
UiSettingsStore.ctorParameters = () => [];
UiSettingsStore = __decorate([
    Injectable(),
    StoreConfig({ name: StoreNames.UiSettings }),
    __metadata("design:paramtypes", [])
], UiSettingsStore);
export { UiSettingsStore };
