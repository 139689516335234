import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { UserWorkspaceRole } from '@ho/shared/data-access/session';
import { TranslateService } from '@ngx-translate/core';
export const WorkspaceRoleTypesMap = new Map([
    [UserWorkspaceRole.Admin, 'Global.UserRole.Admin'],
    [UserWorkspaceRole.AM, 'Global.UserRole.Manager'],
    [UserWorkspaceRole.Aff, 'Global.UserRole.Affiliate'],
    [UserWorkspaceRole.AD, 'Global.UserRole.Advertiser'],
]);
let WorkspaceRoleTypeservice = class WorkspaceRoleTypeservice {
    constructor(_translate) {
        this._translate = _translate;
        this._data = WorkspaceRoleTypesMap;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
};
WorkspaceRoleTypeservice.ctorParameters = () => [
    { type: TranslateService }
];
WorkspaceRoleTypeservice = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], WorkspaceRoleTypeservice);
export { WorkspaceRoleTypeservice };
