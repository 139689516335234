import { __decorate, __metadata } from "tslib";
import __NG_CLI_RESOURCE__0 from "!raw-loader!./dashboard-visible-config-modal.container.html";
import __NG_CLI_RESOURCE__1 from "./dashboard-visible-config-modal.container.scss";
import { Component, HostBinding, Inject } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { debounceTime, delay, distinctUntilChanged, finalize, map, startWith } from 'rxjs/internal/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { DashboardSettingsService } from '../../../../services/dashboard-settings.service';
import { DashboardWidget } from '../../../../constants/dashboard-widget.enum';
let DashboardVisibleConfigModalContainer = class DashboardVisibleConfigModalContainer {
    constructor(data, _dialogRef, _fb, _dashboardSettingsService) {
        this.data = data;
        this._dialogRef = _dialogRef;
        this._fb = _fb;
        this._dashboardSettingsService = _dashboardSettingsService;
        this.form = this._fb.group({});
        this.searchTerm = new FormControl('');
        this._isLoading = false;
    }
    get isLoading() {
        return this._isLoading;
    }
    ngOnInit() {
        this.widgetList = this.data.ids.reduce((acc, id) => {
            acc.push({
                id,
                label: this.data.dashboardSettings[id].label,
                selected: !!this.data.dashboardSettings[DashboardWidget.Visible][id],
            });
            return acc;
        }, []);
        this.widgetList.forEach((item) => {
            this.form.addControl(item.id, this._fb.control(item.selected));
        });
        this.visibleControls$ = this.searchTerm.valueChanges.pipe(debounceTime(300), startWith(''), distinctUntilChanged(), map((term) => {
            if (!term) {
                return this.widgetList;
            }
            const lowerTerm = term.toLowerCase();
            return this.widgetList.filter((item) => item.label.toLowerCase().includes(lowerTerm));
        }), untilDestroyed(this));
    }
    isAllSelected() {
        return !Object.keys(this.form.value).some((k) => this.form.get(k).value === false);
    }
    masterToggle() {
        const flag = !this.isAllSelected();
        const formValue = this.widgetList.reduce((acc, k) => {
            acc[k.id] = flag;
            return acc;
        }, {});
        this.form.setValue(formValue);
    }
    onSave() {
        this._isLoading = true;
        const result = this.form.value;
        this._dashboardSettingsService
            .updateDashboardConfig(DashboardWidget.Visible, result)
            .pipe(delay(1000))
            .pipe(finalize(() => (this._isLoading = false)))
            .subscribe(() => this._dialogRef.close(result));
    }
    ngOnDestroy() { }
};
DashboardVisibleConfigModalContainer.ctorParameters = () => [
    { type: undefined, decorators: [{ type: Inject, args: [MAT_DIALOG_DATA,] }] },
    { type: MatDialogRef },
    { type: FormBuilder },
    { type: DashboardSettingsService }
];
DashboardVisibleConfigModalContainer.propDecorators = {
    isLoading: [{ type: HostBinding, args: ['class.loading',] }]
};
DashboardVisibleConfigModalContainer = __decorate([
    Component({
        selector: 'ho-dashboard-visible-config-modal-container',
        template: __NG_CLI_RESOURCE__0,
        styles: [__NG_CLI_RESOURCE__1]
    }),
    __metadata("design:paramtypes", [Object, MatDialogRef,
        FormBuilder,
        DashboardSettingsService])
], DashboardVisibleConfigModalContainer);
export { DashboardVisibleConfigModalContainer };
